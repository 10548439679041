import React from 'react';
import './head404.scss';
import Button from '../../atoms/button/button';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';
import { documentToReactComponents, } from '@contentful/rich-text-react-renderer';
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import { BLOCKS } from '@contentful/rich-text-types';

const Head404 = () => {

   const data = useStaticQuery(graphql`
		query QUERY_404 {
			allContentfulHome(filter: { contentful_id: { eq: "5u6Xd4ijda6BS91FIWos4Y" }}) {
				nodes {
					referencia404 {
                  tituloPrincipal
                  descripcion {
                     json
                  }
                  tituloBoton
                  tituloBotonSecundario
                  imagenPrincipal {
                     title
                     file {
                        url
                     }
                  }
               }
				}
			}
		}
   `)

   const { isBrowser, localStorage } = useBrowserMode(); 
   const { referencia404 } = data.allContentfulHome.nodes[0];

   const Text = (children: any) => (
      <p className="o-head404__container__text">{children}</p>
   )

   const options = {
      renderNode: {
         [BLOCKS.PARAGRAPH]: (_node: any, children: any) => Text(children)
      },
   };

   const switchButton = () => {
      if (isBrowser) {
         const redirect: any = localStorage.getItem('Redirection')
         const microApp = JSON.parse(redirect);

         if (microApp !== null || undefined) {
            const microAppName = microApp.titleButton || 'COMFAMA.COM';
            const microAppUrl = microApp.path || '';
            switch (microAppUrl) {
               case 'bibliotecas':
                  return <Button
                     classname='o-head404__container__button'
                     gtmMark={'gtmVolverBibliotecas'}
                     isDisabled={false}
                     target={'_self'}
                     type={'link'}
                     text={`IR A ${microAppName}`}
                     strictCss={true}
                     href={withPrefix(`/${microAppUrl}`) || `/${microAppUrl}`}
                  />;
               case 'red-de-amor':
                  return <Button
                     classname='o-head404__container__button'
                     gtmMark={'gtmVolverRedAmor'}
                     isDisabled={false}
                     target={'_self'}
                     type={'link'}
                     text={`IR A ${microAppName}`}
                     strictCss={true}
                     href={withPrefix(`/${microAppUrl}`) || `/${microAppUrl}`}
                  />;
               case 'peso-saludable':
                  return <Button
                     classname='o-head404__container__button'
                     gtmMark={'gtmVolverRedAmor'}
                     isDisabled={false}
                     target={'_self'}
                     type={'link'}
                     text={`IR A ${microAppName}`}
                     strictCss={true}
                     href={withPrefix(`/${microAppUrl}`) || `/${microAppUrl}`}
                  />;
               case 'nutricion-saludable':
                  return <Button
                     classname='o-head404__container__button'
                     gtmMark={'gtmVolverNutricionSaludable'}
                     isDisabled={false}
                     target={'_self'}
                     type={'link'}
                     text={`IR A ${microAppName}`}
                     strictCss={true}
                     href={withPrefix(`/${microAppUrl}`) || `/${microAppUrl}`}
                  />;                  
               case 'mente-y-cuerpo':
                  return <Button
                     classname='o-head404__container__button'
                     gtmMark={'gtmVolverMenteCuerpo'}
                     isDisabled={false}
                     target={'_self'}
                     type={'link'}
                     text={`IR A ${microAppName}`}
                     strictCss={true}
                     href={withPrefix(`/${microAppUrl}`) || `/${microAppUrl}`}
                  />;
               case 'ayuda':
                  return <Button
                     classname='o-head404__container__button'
                     gtmMark={'gtmVolverCentroAyuda'}
                     isDisabled={false}
                     target={'_self'}
                     type={'link'}
                     text={`IR A ${microAppName}`}
                     strictCss={true}
                     href={withPrefix(`/${microAppUrl}`) || `/${microAppUrl}`}
                  />;
               case 'portal-servicios':
                  return <Button
                     classname='o-head404__container__button'
                     gtmMark={'gtmVolverPortalServicios'}
                     isDisabled={false}
                     target={'_self'}
                     type={'link'}
                     text={`IR A ${microAppName}`}
                     strictCss={true}
                     href={withPrefix(`/${microAppUrl}`) || `/${microAppUrl}`}
                  />;
               default:
                  return <Button
                     classname='o-head404__container__button'
                     gtmMark={'gtmVolverInicio'}
                     isDisabled={false}
                     target={'_self'}
                     type={'link'}
                     text={`IR A COMFAMA.COM`}
                     strictCss={true} href={withPrefix('/') || '/'}
                  />;
            }
         } else {
            return <Button
               classname='o-head404__container__button'
               gtmMark={'gtmVolverInicio'}
               isDisabled={false}
               target={'_self'}
               type={'link'}
               text={`IR A COMFAMA.COM`}
               strictCss={true} href={withPrefix('/') || '/'}
            />;
         }

      } else {
         return <Button
            classname='o-head404__container__button'
            gtmMark={'gtmVolverInicio'}
            isDisabled={false}
            target={'_self'}
            type={'link'}
            text={`IR A COMFAMA.COM`}
            strictCss={true} href={withPrefix('/') || '/'}
         />;
      }
   }

   return (
      <>
         {isBrowser && <section className='o-head404'>
            <article className='o-head404__container'>
               <h1 className='o-head404__container__title' >{referencia404.tituloPrincipal}</h1>
               {referencia404.descripcion.json.content && documentToReactComponents(referencia404.descripcion.json, options)}
               <figure className='o-head404__container__cover'>
                  <img
                     className='o-head404__container__cover__img'
                     src={referencia404.imagenPrincipal && referencia404.imagenPrincipal.file.url}
                     alt={referencia404.imagenPrincipal.title}
                     loading="lazy"
                  />
               </figure>
               <div className='o-head404__container__btns'>
                  {switchButton()}
               </div>
            </article>
            <figure className='o-head404__cover'>
               <img loading="lazy" className='o-head404__cover__img' src={referencia404.imagenPrincipal && referencia404.imagenPrincipal.file.url} alt={referencia404.imagenPrincipal.title} />
            </figure>
         </section>}
      </>
   )
}

export default Head404;
